/* eslint-disable vue/no-unused-components */
<template>
  <div class="view-hotel-list-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Hotels</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <form class="search-form mr-form">
                        <input
                          type="text "
                          class="form-control"
                          v-model="searchText"
                          @input="viewHotelList(1)"
                          placeholder="Search Here..."
                        />
                      </form>
                      <!-- create router link -->
                      <!-- <router-link to="CreateUser" class="btn btn-success
                      btn-small">Create</router-link>-->
                    </div>
                    <div class="d-flex">
                      <div>
                        <h3>
                          List of Hotels
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h3>
                      </div>
                      <div class="ml-3">
                        <b-button variant="success" @click="generateExcelForHotel()"
                          >Download</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Hotel Name</th>
                          <th>Date of Sign-up</th>
                          <th>
                            <!-- <v-select label="pacakge" v-model="selected" :options="options" return-object></v-select> -->

                            <b-dropdown :text="selected ? selected.text : 'Package'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                v-for="option in packages"
                                @click="getSelectedPackge(option)"
                                :key="option.value"
                              >
                                <div class="text">{{ option.text }}</div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>
                          <!-- <th>Phone</th> -->
                          <th>
                            <b-dropdown :text="selected1 ? selected1.text : 'Verify Status'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="getVerifiedStatus(option)"
                                v-for="option in status"
                                :key="option.value"
                              >
                                <div class="text">{{ option.text }}</div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>
                          <th>City</th>
                          <th>State</th>
                          <th>Country</th>
                          <th>Last Login Date &amp; Time</th>
                          <th>
                            <b-dropdown :text="selected2 ? selected2.text : 'Current Status'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="selectedCurrentStatus(option)"
                                v-for="option in currentStatus"
                                :key="option.value"
                              >
                                <div class="text">{{ option.text }}</div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>

                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in users" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <td>
                            <div v-if="item.user">{{ item.user.name }}</div>
                          </td>
                          <td>
                            <div v-if="item.user">{{ item.user.email }}</div>
                          </td>
                          <td>
                            <div v-if="item.user">{{ item.user.phone }}</div>
                          </td>
                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div v-if="item.user && item.user.createdAt">
                              {{ item.user.createdAt | moment("DD/MM/YYYY") }}
                            </div>
                            <div v-else-if="item.user && item.user.userCreatedAt">
                              {{ item.user.userCreatedAt | moment("DD/MM/YYYY") }}
                            </div>
                          </td>
                          <td>
                            <div v-if="item.package">
                              <!-- {{ user.package.name + "(" + user.package.type + ")" }} -->
                              {{ item.package.name }} {{ item.package.type }}
                            </div>
                          </td>

                          <!-- <td>
                            <div>{{ user.phone }}</div>
                          </td>-->
                          <!-- <td> 
                            <div>{{ user.isVerified == false ? "Not Verified" : "Verified" }}</div>
                          </td>-->
                          <td v-if="item.isVerified == 'true'" class="green">
                            <div>Verified</div>
                          </td>
                          <td v-if="item.isVerified == 'false'" class="red">
                            <div>Not Verified</div>
                          </td>
                          <td v-if="item.isVerified == 'pending'" class="red">
                            <div>Pending</div>
                          </td>
                          <td>
                            <div>{{ item.city }}</div>
                          </td>
                          <td>
                            <div>{{ item.state }}</div>
                          </td>
                          <td>
                            <div>{{ item.country }}</div>
                          </td>
                          <td>
                            <div v-if="item.user && item.user.loginData">
                              {{
                                item.user.loginData && item.user.loginData.length > 0
                                  ? moment(
                                      item.user.loginData[item.user.loginData.length - 1].lastLogin
                                    ).format("DD/MM/YYYY hh:mm A")
                                  : ""
                              }}
                            </div>
                          </td>
                          <td v-if="!item.package">
                            <div>Signup Completed</div>
                          </td>
                          <td v-else-if="item.package && item.progressCompleted == false">
                            <div>Package Selected</div>
                          </td>
                          <td v-else-if="item.progressCompleted">
                            <div>Completed</div>
                          </td>
                          <!-- <td v-else>
                            <div>Finish</div>
                          </td>-->
                          <!-- v-repeat -->
                          <td>
                            <router-link
                              class="btn btn-primary"
                              :to="{ name: 'hotel', params: { id: item._id } }"
                              append
                            >
                              <!-- <font-awesome-icon :icon="['fas', 'edit']" /> -->
                              <b-icon-eye></b-icon-eye>
                            </router-link>
                          </td>
                        </tr>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="users.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="users.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewHotelList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import moment from "moment";
import apiservice from "@/service/apiservice";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      moment: moment,
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      users: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      selected: null,
      selected1: null,
      selected2: null,
      packages: [
        {
          text: "GOLD PLUS 3 Star",
          value: {
            name: "GOLD PLUS",
            type: "3star"
          }
        },
        {
          text: "GOLD PLUS 4 Star",
          value: {
            name: "GOLD PLUS",
            type: "4star"
          }
        },
        {
          text: "GOLD PLUS 5 Star",
          value: {
            name: "GOLD PLUS",
            type: "5star"
          }
        },
        {
          text: "GOLD 3 Star",
          value: {
            name: "GOLD",
            type: "3star"
          }
        },
        {
          text: "GOLD 4 Star",
          value: {
            name: "GOLD",
            type: "4star"
          }
        },
        {
          text: "GOLD 5 Star",
          value: {
            name: "GOLD",
            type: "5star"
          }
        },
        {
          text: "SILVER 3 Star",
          value: {
            name: "SILVER",
            type: "3star"
          }
        },
        {
          text: "SILVER 4 Star",
          value: {
            name: "SILVER",
            type: "4star"
          }
        },
        {
          text: "SILVER 5 Star",
          value: {
            name: "SILVER",
            type: "5star"
          }
        },
        {
          text: "PLATINUM 3 Star",
          value: {
            name: "PLATINUM",
            type: "3star"
          }
        },
        {
          text: "PLATINUM 4 Star",
          value: {
            name: "GOLD",
            type: "4star"
          }
        },
        {
          text: "PLATINUM 5 Star",
          value: {
            name: "PLATINUM",
            type: "5star"
          }
        }
      ],
      status: [
        {
          text: "All",
          value: "All"
        },
        {
          text: "Verified",
          value: "true"
        },
        {
          text: "Not verified",
          value: "false"
        },
        {
          text: "Pending",
          value: "pending"
        }
      ],
      currentStatus: [
        {
          text: "Package Selected",
          value: "Package Selected"
        },
        {
          text: "Signup Completed",
          value: "Signup Completed"
        },
        {
          text: "Completed",
          value: "Completed"
        }
      ]
    };
  },
  methods: {
    viewHotelList(pageNo) {
      this.users = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      apiservice.viewHotelList(this.formData, data => {
        if (data.code === 200) {
          this.users = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    generateExcelForHotel() {
      apiservice.generateExcelForHotel({}, data => {
        apiservice.downloadExcel(data, "Hotel_List");
      });
    },
    getSelectedPackge(option) {
      this.selected = option;
      //   this.formData.package = option.value;
      //   this.viewHotelList(this.page);
    },
    getVerifiedStatus(option) {
      this.selected1 = option;
      if (option.text == "All") {
        this.formData.type = option.value;
        this.formData.isVerified = "";
      } else {
        this.formData.isVerified = option.value;
        this.formData.type = "";
      }
      this.viewHotelList(this.page);
    },
    selectedCurrentStatus(option) {
      this.selected2 = option;
      //   this.formData.currentStatus = option.value;
      //   this.viewHotelList(this.page);
    }
  },
  created() {
    this.viewHotelList(this.page);
  }
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.view-hotel-list-section {
  .red {
    color: red;
  }
  .green {
    color: #4ca846;
  }
  .text {
    color: black;
  }
  .btn-secondary {
    background: transparent;
    border-color: transparent;
    color: black;
    box-shadow: none;
    font-weight: 700;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border-color: transparent;
      color: black;
      box-shadow: none;
    }
  }
  .table .btn {
    padding: 0px 6px;
  }
}
</style>
